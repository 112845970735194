<template>
  <div
    class="main"
    v-loading="loading"
    element-loading-text="系统分析中，请等待..."
  >
    <heads></heads>
    <banners></banners>
    <!-- <islogin /> -->
    <div class="content">
      <div class="cons">
        <div class="cons-title">分词</div>
        <shuoming :smdata="smdata" :srcList="srcList"></shuoming>
        <div class="cons-title-subtitle">
          <p></p>
        </div>
        <div class="cons-form">
          <el-form :model="form" label-width="120px">
            <div class="custom-block">请输入一段文本，系统将为您分词</div>
            <el-form-item label="输入文本">
              <el-input
                :rows="10"
                type="textarea"
                v-model="form.writerorg"
                placeholder="请输入文本，按ctrl+enter或点击下方按钮提交"
                @keydown.ctrl.enter="onSubmit()"
              />
            </el-form-item>

            <div class="con-btn">
              <el-button type="primary" @click="onSubmit()">立即提交</el-button>
            </div>
          </el-form>
        </div>
        <div class="tables">
          <div class="custom-block">分词结果</div>
          <div class="xsfx">
            <div id="echartsyyy"></div>
          </div>
        </div>
        <div class="tables">
          <div class="custom-block">分词结果</div>
          <el-table :data="tablelist" border  style="width: 100%">
            <el-table-column prop="key" label="词语" />
            <el-table-column prop="tf" sortable  label="tf" />
            <el-table-column prop="idf" sortable  label="idf" />
          </el-table>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref, inject, getCurrentInstance } from "vue";
import "echarts-wordcloud";
import { useRouter } from "vue-router";
// 图片
let smdata = reactive([
  { url: "/img/shuoming/11-01.png", tips: "输入文本" },
  { url: "/img/shuoming/11-02.png", tips: "获取结果" },
]);
let srcList = reactive([
  "/img/shuoming/11-01.png",
  "/img/shuoming/11-02.png",
]);
const router = useRouter();
const { proxy } = getCurrentInstance();
// 词云
let $echarts = inject("echarts");
// ciyun
let stateciyun = reactive({
  option: {
    series: [
      {
        type: "wordCloud",
        // shape这个属性虽然可配置，但是在词的数量不太多的时候，效果不明显，它会趋向于画一个椭圆
        shape: "circle",
        // 这个功能没用过
        keepAspect: false,
        // maskImage这个是可以自定义背景图片的，词云会按照图片的形状排布，所以有形状限制的时候，最好用背景图来实现，而且，这个背景图一定要放base64的，不然词云画不出来
        // maskImage: '',
        // 下面就是位置的配置
        left: "center",
        top: "center",
        width: "90%",
        height: "90%",
        right: null,
        bottom: null,
        // 词的大小，最小12px，最大60px，可以在这个范围调整词的大小
        sizeRange: [12, 60],
        // 每个词旋转的角度范围
        rotationRange: [-90, 90],
        rotationStep: 45,
        // 词间距，数值越小，间距越小，这里间距太小的话，会出现大词把小词套住的情况，比如一个大的口字，中间会有比较大的空隙，这时候他会把一些很小的字放在口字里面，这样的话，鼠标就无法选中里面的那个小字
        gridSize: 8,
        // 允许词太大的时候，超出画布的范围
        drawOutOfBound: false,
        // 布局的时候是否有动画
        layoutAnimation: true,
        // 这是全局的文字样式，相对应的还可以对每个词设置字体样式
        textStyle: {
          fontFamily: "sans-serif",
          fontWeight: "bold",
          // 颜色可以用一个函数来返回字符串
          color: function () {
            // Random color
            return (
              "rgb(" +
              [
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160),
              ].join(",") +
              ")"
            );
          },
        },
        emphasis: {
          focus: "self",
          textStyle: {
            textShadowBlur: 10,
            textShadowColor: "#333",
          },
        },

        // 数据必须是一个数组，数组是对象，对象必须有name和value属性
        data: [],
      },
    ],
  },
});
let ciyundatas = reactive([]);
// loading
let loading = ref(false);
let tablelist = reactive([]);
let form = reactive({
  writerorg: "",
});

// 词云
const ciyun = () => {
  // 词云
  let mychart = proxy.$echarts.init(document.getElementById("echartsyyy"));
  // 这里是官方给出的一些基本的配置项，我做一些说明
  mychart.setOption(stateciyun.option);
};

// 提交代码
const onSubmit = () => {
  loading.value = true;
  tablelist.length = 0;
  post("/journalCommon/GetCuts", {
    sentence: form.writerorg,
  })
    .then((res) => {
      loading.value = false;
      // console.log(res);
      tablelist.push(...res.data)
      ciyundatas.length = 0;
      ciyundatas.push(...res.data);
      ciyundatas.forEach((e) => {
        e.name = e.key;
        e.value = e.idf;
      });
      stateciyun.option.series[0].data = ciyundatas;
      setTimeout(() => {
        ciyun();
      }, 100);
    })
    .catch((err) => {
      loading.value = false;
    });
};
</script>

<style scoped>
:deep(.el-form-item__label) {
  background: #f5f7fa;
  height: 42px;
  line-height: 42px;
  font-size: 18px;
}
:deep(.cons-form .el-input__inner) {
  height: 40px;
  font-size: 18px;
}
:deep(.cons-form .el-textarea__inner) {
  min-height: 100px !important;
  font-size: 18px;
}
:deep(.cons-form .el-button) {
  width: 180px;
  height: 50px;
  font-size: 18px;
}
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.cons-form {
  width: 100%;
}
.cons-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 0 0 10px 0;
}
.cons-subtitle {
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  background: #f5f7fa;
  font-size: 14px;
  line-height: 20px;
  color: #999;
  margin: auto;
  margin-bottom: 30px;
}
.custom-block {
  padding: 10px 16px;
  background-color: #ecf5ff;
  border-radius: 4px;
  border-left: 5px solid #409eff;
  margin: 20px 0 20px 0;
  font-size: 18px;
}
.con-btn {
  text-align: center;
}
.cons-title-subtitle {
  color: #999;
  font-size: 14px;
}
.cons-title-subtitle p {
  margin: 0;
  padding: 0;
  text-align: center;
}
.qksx-list-con {
  font-size: 14px;
  padding: 20px 0 0 0;
}
.qksx-list-con:after {
  content: "";
  display: table;
  clear: both;
}
.xsqk-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.xsqk-list {
  cursor: pointer;
}
.xsqk-list-title {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
}
.xsfx,
#echartsyyy {
  width: 1200px;
  height: 600px;
}
</style>
